<template>
  <layout-3
    name="business-completed"
    :title="$t('business.signup.completed.title')"
    :subtitle="$t('business.signup.completed.subtitle')"
    :image="{ src: 'business-welcome', width: 560, height: 486 }"
  >
    <ul class="business-signup-completed__list pb-8">
      <li>
        <i18n path="business.signup.completed.benefit_list.item_1">
          <template #date>
            {{ trialEnd }}
          </template>
        </i18n>
      </li>
      <li>
        {{
          $tc('business.welcome.free_signatures', availableSignatures, {
            signatureCount: $tc('global.signature_count', availableSignatures),
          })
        }}
      </li>
    </ul>
    <v-btn
      :nuxt="true"
      :to="{ name: 'index' }"
      class="ma-0 px-14"
      color="info"
      x-large
      data-cy="startNowButton"
      width="max-content"
    >
      {{ $t('business.signup.completed.cta') }}
    </v-btn>
  </layout-3>
</template>

<script lang="ts">
import Vue from 'vue'

import Layout3 from '~/components/layouts/Layout3.vue'

export default Vue.extend({
  components: { Layout3 },
  provide() {
    return {
      headingBottomPaddingClass: 'pb-1',
    }
  },
  props: {
    /* Date to be formatted externally to something human readable like, 1 February 2023 */
    trialEnd: String,
    availableSignatures: Number,
  },
})
</script>

<style lang="sass">
$ns: business-signup-completed

.#{$ns}
  &__list
    li
      font-size: 20px
</style>
