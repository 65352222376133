import BusinessSignupCompleted from '@/components/signup/BusinessSignupCompleted.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import useDate from '@/composables/useDate';
import { useBusinessStore } from '@/stores/business';
export default defineComponent({
  components: {
    SiteHeader: SiteHeader,
    BusinessSignupCompleted: BusinessSignupCompleted
  },
  provide: {
    headingBottomPaddingClass: 'pb-4'
  },
  props: {
    origin: {
      type: String,
      default: ''
    }
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    var _useDate = useDate(),
      formatDate = _useDate.formatDate;
    var trialEndDisplay = computed(function () {
      if (!businessStore.trialEndDate) return '';
      return formatDate(businessStore.trialEndDate);
    });
    var availableSignatures = computed(function () {
      var _businessStore$curren, _businessStore$curren2;
      return (_businessStore$curren = (_businessStore$curren2 = businessStore.currentPricePlan) === null || _businessStore$curren2 === void 0 ? void 0 : _businessStore$curren2.signatures.SES_AES_QES.limit) !== null && _businessStore$curren !== void 0 ? _businessStore$curren : 20;
    });
    return {
      trialEndDisplay: trialEndDisplay,
      availableSignatures: availableSignatures
    };
  },
  head: function head() {
    return {
      title: 'Business - Welcome'
    };
  }
});